html {
  width: 100%;
  height: 100%;
  min-height: 100%; }

body {
  background: #ffffff;
  background-size: cover;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  padding: 0px; }

.body-web-1 {
  background: #ffffff url("../images/bg.web.1.jpg") no-repeat right top;
  background-size: cover; }

.body-web-2 {
  background: #ffffff url("../images/bg.web.2.jpg") no-repeat right top;
  background-size: cover; }

.body-web-3 {
  background: #ffffff url("../images/bg.web.3.jpg") no-repeat right top;
  background-size: cover; }

.body-medical-1 {
  background: #ffffff url("../images/bg.medical.1.jpg") no-repeat right top;
  background-size: cover; }

.body-medical-2 {
  background: #ffffff url("../images/bg.medical.2.jpg") no-repeat right top;
  background-size: cover; }

.body-medical-3 {
  background: #ffffff url("../images/bg.medical.3.jpg") no-repeat right top;
  background-size: cover; }

.body-education-1 {
  background: #ffffff url("../images/bg.education.1.jpg") no-repeat right top;
  background-size: cover; }

.body-education-2 {
  background: #ffffff url("../images/bg.education.2.jpg") no-repeat right top;
  background-size: cover; }

.body-education-3 {
  background: #ffffff url("../images/bg.education.3.jpg") no-repeat right top;
  background-size: cover; }

.body-finance-1 {
  background: #ffffff url("../images/bg.finance.1.jpg") no-repeat right top;
  background-size: cover; }

.body-finance-2 {
  background: #ffffff url("../images/bg.finance.2.jpg") no-repeat right top;
  background-size: cover; }

.body-finance-3 {
  background: #ffffff url("../images/bg.finance.3.jpg") no-repeat right top;
  background-size: cover; }

.container, .container-search {
  position: absolute;
  top: 0px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0; }

.container-search {
  background-color: rgba(144, 144, 144, 0.87); }

.well {
  margin: 10px; }

div.row-search-input {
  margin: 0px; }

@media (max-width: 767px) {
  .container {
    background-color: rgba(0, 0, 0, 0.57); }
  .domain-select {
    margin-bottom: 5px; }
  div#iSeekSearchForm #iSeekSearchBox {
    width: 200px !important;
    min-width: 0px !important;
    margin-left: 10px; }
  div#iSeekSearchForm form {
    width: 260px !important; }
  div#iSeekSearchButton {
    width: 40px; }
  .well-home .row-search-input {
    margin-top: 0px; }
  div.col-search-input {
    padding: 0px; }
  .well-home .row-search-domain {
    margin-top: 40px; }
  #genericContent {
    padding: 25px 0px 0px 0px; }
  .container-search {
    padding: 60px 0 45px 0; }
  .navbar-brand {
    background: transparent url(../images/logo.iseek.small.svg) no-repeat left center;
    background-size: 100% auto;
    color: #777;
    display: inline-block;
    float: left;
    font-size: 32px;
    width: 40px;
    margin-top: 5px;
    margin-left: 10px !important;
    overflow: hidden;
    text-indent: -100000px; }
  #iSeekTargetButton {
    display: none !important; } }

@media (min-width: 768px) {
  .well-home {
    background: rgba(0, 0, 0, 0.3);
    height: 460px;
    width: 600px;
    float: right;
    margin: 100px 100px 0 0; }
  .well-home div#iSeekSearchForm #iSeekSearchBox {
    height: 50px;
    width: 455px; }
  .well-home .row-search-input {
    margin-top: 30px; }
  .well-home .row-search-domain {
    margin-top: 70px; }
  .navbar-container {
    padding: 10px 25px 0 25px;
    height: 75px; }
  .container-search {
    padding: 90px 0 45px 0; }
  .navbar-brand {
    background: transparent url(../images/logo.new_iseek.svg) no-repeat left center;
    background-size: 100% auto;
    color: #777;
    display: inline-block;
    float: none;
    font-size: 32px;
    width: 175px;
    margin-top: 5px;
    margin-left: 0px !important;
    overflow: hidden;
    text-indent: -100000px; }
  .domain-button-container {
    margin-right: 15px; }
  #iSeekTargetButton {
    display: inline-block; } }

.well-home div#iSeekSearchForm form {
  width: 100%; }

#iSeekSearchBox {
  background: #FFF !important; }

.well-home div#iSeekSearchForm #iSeekSearchBox {
  height: 50px;
  border-radius: 0px; }

.navbar-container div#iSeekSearchForm form {
  width: 350px; }

#iSeekSearchBox {
  border-radius: 0px !important; }

#iSeekSearchButton {
  border-radius: 0px !important; }

.well-home .fa-fw {
  margin-top: 11px; }

.well-home .row-search-domain {
  color: #ccc; }

.well-home div#iSeekSearchControls button {
  height: 50px;
  border-radius: 0px; }

#iSeekTargetButton {
  left: 4px !important;
  border-radius: 0px !important; }

.well-home .iseek-main-logo {
  height: 200px;
  width: 500px;
  margin: 0 auto;
  background: transparent url(../images/logo.new_iseek_white.svg) no-repeat left center; }

.well-search {
  height: 100%;
  min-height: 100%; }

.target-row {
  margin-bottom: 10px; }

.target-row label {
  margin-right: 10px; }

.target-row button {
  margin-left: 10px;
  font-size: 10pt; }

.target-row input {
  display: inline-block;
  width: 400px; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px; }
  .navbar-brand.logo .logo-img {
    height: 45px;
    display: inline-block; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover, .hand, [jh-sort-by] {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td vertically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px 10px 0px 10px;
  /* Set the fixed height of the footer here */
  height: 35px;
  background: rgba(0, 0, 0, 0.5); }

.footer-links {
  padding-left: 0; }

.footer {
  color: #cccccc; }

.footer a {
  color: #cccccc; }

.footer-links li {
  display: inline-block; }

.footer-links li + li {
  margin-left: 15px; }

.navbar {
  z-index: 2;
  min-height: 50px;
  color: #000 !important;
  background-color: #FFF !important;
  border-bottom: thin solid #F79420; }

.navbar-clear {
  color: #cccccc !important;
  background-color: transparent  !important;
  border-bottom: none; }

.navbar-collapse.collapse {
  display: block !important;
  border-top-width: 0px; }

/** Provide full page height for content, but pad enough space for the header/footer **/
div#genericContent {
  width: 100%;
  height: 100%;
  overflow: hidden; }

/* Adjust positioning of iSEEK's search form, relative to logos or other
 * header elements
 */
div#iSeekSearchForm {
  display: inline-block;
  vertical-align: bottom;
  height: 34px; }

/* Adjust positioning of iSEEK's search results, relative to other page
 * content or navigation elements
 */
div#iSeekSearchResults {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.btn.btn-fab, .input-group-btn .btn.btn-fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal; }

.btn.btn-raised, .input-group-btn .btn.btn-raised, .btn.btn-fab, .input-group-btn .btn.btn-fab, .btn-group-raised .btn, .btn-group-raised .input-group-btn .btn, .btn.btn-raised.btn-default, .input-group-btn .btn.btn-raised.btn-default, .btn.btn-fab.btn-default, .input-group-btn .btn.btn-fab.btn-default, .btn-group-raised .btn.btn-default, .btn-group-raised .input-group-btn .btn.btn-default {
  background-color: #FFF;
  color: rgba(0, 0, 0, 0.87); }

.btn.btn-raised.btn-warning, .input-group-btn .btn.btn-raised.btn-warning, .btn.btn-fab.btn-warning, .input-group-btn .btn.btn-fab.btn-warning, .btn-group-raised .btn.btn-warning, .btn-group-raised .input-group-btn .btn.btn-warning {
  background-color: #F79420; }

.row {
  margin-left: 15px;
  margin-right: 15px; }

.fa-2 {
  font-size: 1.5em; }

.btn.btn-fab.btn-fab-mini, .input-group-btn .btn.btn-fab.btn-fab-mini, .btn-group-sm .btn.btn-fab, .btn-group-sm .input-group-btn .btn.btn-fab {
  height: 32px;
  min-width: 32px;
  width: 32px; }

.btn.btn-fab.btn-fab-mini, .input-group-btn .btn.btn-fab.btn-fab-mini, .btn-group-sm .btn.btn-fab, .btn-group-sm .input-group-btn .btn.btn-fab {
  margin-left: 10px; }

.btn i.material-icons, .input-group-btn .btn i.material-icons {
  vertical-align: middle; }

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #9d9d9d;
  background-color: transparent; }

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #9d9d9d; }
